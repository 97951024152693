


















































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { BoulderDetails } from '@/domain/boulder/BoulderDetails'
import BoulderCanvas from '@/components/boulder/canvas/boulder-canvas.vue'
import GradeAvatar from '@/components/boulder/list/GradeAvatar.vue'
import Button from '@/infrastructure/ui/button.vue'
import { BoulderId } from '@/domain/boulder/Id'
import DeleteOnConfirmation from '@/components/boulder/delete/index.vue'

@Component({
    components: { Button, BoulderCanvas, GradeAvatar, customButton: Button, DeleteOnConfirmation }
})
export default class DetailView extends Vue {
    @Prop({ type: Object, required: true }) boulder!: BoulderDetails
    @Prop({ type: String, required: true }) userName!: string
    @Prop({ type: Boolean, default: false, required: true }) editMode!: boolean
    @Prop({ type: Boolean, required: true }) pending!: boolean
    @VModel({ type: Boolean, required: true }) showDetails!: boolean

    get createdAt(): string {
        return new Date(this.boulder.createdAt).toLocaleDateString()
    }

    loadBoulder(id: BoulderId): void {
        this.$emit('load-boulder', id)
    }

    deletable(createdBy: string): boolean {
        return this.userName === createdBy
    }
}
