




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import DetailView from '@/components/boulder/detail/detail-view.vue'
import LoadingCircle from '@/components/LoadingCircle.vue'
import { showSnackbar, SNACKBAR } from '@/infrastructure/EventBus/global-actions/showSnackbar'
import { BoulderDetails } from '@/domain/boulder/BoulderDetails'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/infrastructure/store/user'
import { consume, PATH, provide } from '@/utils/provide-consum-decorator'
import { BoulderStore } from '@/infrastructure/store/boulders'
import store from '@/infrastructure/store'
import { BoulderId } from '@/domain/boulder/Id'
import Layout from '@/components/layout.vue'
import { ControlStore } from '@/infrastructure/store/control'

const authModule = getModule(UserStore)

@Component({
    components: { Layout, LoadingCircle, DetailView }
})
@provide({
    [PATH.USER_API]: () => {
        return getModule(UserStore, store)
    },
    [PATH.BOULDER_API]: () => {
        return getModule(BoulderStore, store)
    },
    [PATH.CONTROL_API]: () => {
        return getModule(ControlStore, store)
    }
})
export default class Detail extends Vue {
    @Prop({ type: String, required: true }) id!: string

    boulder: BoulderDetails | null = null
    loading = false
    pending = false
    showDetails = false

    @consume(PATH.USER_API) userStore!: UserStore
    @consume(PATH.BOULDER_API) boulderStore!: BoulderStore
    @consume(PATH.CONTROL_API) controlStore!: ControlStore

    async mounted(): Promise<void> {
        this.loading = true
        await this.loadBoulder()
        this.loading = false
    }

    @Watch('id')
    private async loadBoulder() {
        try {
            const { boulder, previous, next } = await this.boulderStore.byId(this.id)
            const userData = this.userStore.userSpecificBoulderData
            this.boulder = {
                topped: userData.topped.includes(boulder.id.asString()),
                bookmarked: userData.bookmarks.includes(boulder.id.asString()),
                next,
                previous,
                ...boulder
            }
        } catch (e) {
            console.log(e)
            showSnackbar({
                type: SNACKBAR.ERROR,
                message: 'Boulder konnte nicht geladen werden.'
            })
        }
    }

    async onToggleBookmark(): Promise<void> {
        if (!this.boulder) {
            return
        }

        if (this.boulder.bookmarked) {
            await authModule.removeBookmark(this.id)

            showSnackbar({
                type: SNACKBAR.SUCCESS,
                message: 'Lesezeichen für diesen Boulder wurde entfernt'
            })
        }

        if (!this.boulder.bookmarked) {
            await authModule.addBookmark(this.id)

            showSnackbar({
                type: SNACKBAR.SUCCESS,
                message: 'Boulder wurde deinen Lesezeichen hinzugefügt'
            })
        }

        this.updateBoulder()
    }

    async onToggleTopped(): Promise<void> {
        if (!this.boulder) {
            return
        }

        if (this.boulder.topped) {
            await authModule.removeTopped(this.id)

            showSnackbar({
                type: SNACKBAR.SUCCESS,
                message: 'Boulder wurde von deinen getoppten Bouldern entfernt'
            })
        }

        if (!this.boulder.topped) {
            await authModule.addTopped(this.id)

            showSnackbar({
                type: SNACKBAR.SUCCESS,
                message: 'Boulder wurde deinen getoppten Bouldern hinzugefügt'
            })
        }

        this.updateBoulder()
    }

    onToggleShowDetails(): void {
        this.showDetails = !this.showDetails
    }

    private updateBoulder(): void {
        if (!this.boulder) {
            return
        }

        const userData = authModule.userSpecificBoulderData
        this.boulder = {
            ...this.boulder,
            topped: userData.topped.includes(this.boulder.id.asString()),
            bookmarked: userData.bookmarks.includes(this.boulder.id.asString())
        }
    }

    get bookMarked(): string {
        return this.boulder?.bookmarked ? 'mdi-bookmark' : 'mdi-bookmark-outline'
    }

    get topped(): string {
        return this.boulder?.topped ? 'mdi-check-circle' : 'mdi-check-circle-outline'
    }

    get userName(): string {
        return this.userStore.authenticatedUser?.name || 'k.A.'
    }

    onLoadBoulder(id: BoulderId): void {
        this.$router.push({ name: 'boulder-detail', params: { id: id.asString() } })
    }

    async onDeleteBoulder(id: string): Promise<void> {
        try {
            this.pending = true
            await this.boulderStore.deleteBoulder(BoulderId.fromString(id))
            if (this.boulder && this.boulder.next) {
                this.onLoadBoulder(this.boulder.next)

                return
            }

            if (this.boulder && this.boulder.previous) {
                this.onLoadBoulder(this.boulder.previous)

                return
            }
        } catch (e) {
            console.warn(e.response)
            showSnackbar({
                type: SNACKBAR.ERROR,
                message: e.response?.data?.message || 'Das löschen ist fehlgeschlagen'
            })
        } finally {
            this.pending = false
        }
    }
}
